import { REMOVECART, MERGECART, OPENCART, SETCART } from '../types';
import { fromJS, Map } from 'immutable';
import checkEmptyObject from '/utils/checkEmptyObject';
import getUUID from '/utils/getUUID';
import { getCookie, setCookie } from '/utils/cookie';

// change or set cart
const mergeCart = (cart_map, openCart) => {
  return (dispatch, getState) => {
    let cart_key = 'cart';
    let hash_key = 'cart';
    let prev_state = {};
    if (getState().subdomain === 'business') {
      cart_key = 'cart_business';
      hash_key = 'hash_business';
    }
    const cart_from_storage = window.safeStorage.getItem(cart_key);
    if (cart_from_storage) {
      if (!checkEmptyObject(JSON.parse(cart_from_storage))) {
        prev_state = JSON.parse(cart_from_storage);
      }
    }
    if (getCookie(hash_key)) {
      setCookie(hash_key, getCookie(hash_key));
    } else {
      // if there's no cart hash, create hash (like cart id)
      setCookie(hash_key, getUUID());
    }
    if (!Map.isMap(prev_state)) {
      prev_state = fromJS(prev_state);
    }
    // eslint-disable-next-line no-unused-vars,no-case-declarations
    const merged_cart = prev_state.mergeWith((prev, next) => {
      const updated_item = next.setIn(['cart_order'], prev.getIn(['cart_order']));
      return updated_item;
    }, cart_map);
    window.safeStorage.setItem(cart_key, JSON.stringify(merged_cart.toJS()));

    dispatch({ type: MERGECART, payload: merged_cart });
    if (openCart) {
      dispatch({ type: OPENCART, payload: true });
    }
  };
};
const removeCart = (cart_map, key) => {
  const payload = cart_map.delete(key);
  return (dispatch, getState) => {
    let cart_key = 'cart';
    let hash_key = 'cart';
    if (getState().subdomain === 'business') {
      cart_key = 'cart_business';
      hash_key = 'hash_business';
    }
    if (getCookie(hash_key)) setCookie(hash_key, getCookie(hash_key));
    window.safeStorage.setItem(cart_key, JSON.stringify(payload.toJS()));
    dispatch({ type: REMOVECART, payload });
  };
};
const setCart = payload => {
  return dispatch => {
    dispatch({ type: SETCART, payload });
  };
};

export default {
  mergeCart,
  removeCart,
  setCart,
};
