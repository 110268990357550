// this file will have the task that we have to perform with cookies/ react-native you need to put your async storage code here.

import cookie from 'js-cookie';
import isJSON from './isJSON';
import CryptoJS from 'crypto-js';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const setCookie = (key, value, expires) => {
  if (process.browser) {
    cookie.set(key, value, {
      // 1/24 = 1 hour
      expires: expires || 30,
      path: '/',
    });
  }
};

export const removeCookie = key => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 30,
    });
  }
};

export const getCookie = (key, req) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

export const getNoTrackerPermissionCookie = () => {
  return getCookie('tr_no_track');
}

const getCookieFromBrowser = key => {
  return cookie.get(key);
};

const getCookieFromServer = (key, req) => {
  if (!req || !req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const setEncryptedCookie = (key, value, expires) => {
  const encrypted_value = CryptoJS.AES.encrypt(JSON.stringify(value),
          CryptoJS.enc.Utf8.parse(publicRuntimeConfig.AES_SECRET),
          { mode: CryptoJS.mode.ECB });
  setCookie(key, encrypted_value, expires);
};

export const getDecryptedCookie = (key, req) => {
  let value = getCookie(key, req);
  if (value) {
    if (!isJSON(value)) {
      value = CryptoJS.AES.decrypt(value, CryptoJS.enc.Utf8.parse(publicRuntimeConfig.AES_SECRET),
        { mode: CryptoJS.mode.ECB }).toString(CryptoJS.enc.Utf8);
    }
  }
  return value;
};