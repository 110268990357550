import authActions from './authActions';
import authCompanyActions from './authCompanyActions';
import authPartnerActions from './authPartnerActions';
import uiActions from './uiActions';
import currencyActions from './currencyActions';
import languageActions from './languageActions';
import recentlyActions from './recentlyActions';
import translationActions from './translationActions';
import cartActions from './cartActions';
import reservationActions from './reservationActions';
import reservedActions from './reservedActions';
import citiesActions from './citiesActions';
import deviceActions from './deviceActions';
import serverActions from './serverActions';
import blogActions from './blogActions';
import dimensionActions from './dimensionActions';
import subDomainActions from './subDomainActions';

export default {
  ...authActions,
  ...authCompanyActions,
  ...authPartnerActions,
  ...uiActions,
  ...currencyActions,
  ...languageActions,
  ...recentlyActions,
  ...translationActions,
  ...cartActions,
  ...reservationActions,
  ...reservedActions,
  ...citiesActions,
  ...deviceActions,
  ...serverActions,
  ...blogActions,
  ...dimensionActions,
  ...subDomainActions,
};
