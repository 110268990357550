import { BLOG } from '../types';

const setBlog = blog_object => {
  return dispatch => {
    dispatch({ type: BLOG, payload: blog_object });
  };
};

export default {
  setBlog,
};
