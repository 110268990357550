import { CURRENCYINFO, CURRENCY } from '../types';

// get current currency
const changeCurrency = currency_obj => {
  return dispatch => {
    dispatch({ type: CURRENCY, payload: currency_obj });
  };
};
const changeCurrencyInfo = currency_arr => {
  return dispatch => {
    dispatch({ type: CURRENCYINFO, payload: currency_arr });
  };
};

export default {
  changeCurrency,
  changeCurrencyInfo,
};
