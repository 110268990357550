import { DEVICE } from '../types';

const setDevice = device_type => {
  return dispatch => {
    dispatch({ type: DEVICE, payload: device_type });
  };
};

export default {
  setDevice,
};
