import {
  SCREENWIDTH,
  SWEETALERT,
  LOADING,
  SUBMITTING,
  MODALMEMBER,
  PHOTOSWIPE,
  OPENCART,
  MODALREVIEW,
  MODALSPOT,
  MODALPHOTO,
} from '../types';

// get current screen width size
const changeScreen = payload => {
  return dispatch => {
    // console.log(dispatch);
    dispatch({ type: SCREENWIDTH, payload });
  };
};
// change sweet alert
const changeSweetAlert = payload => {
  return dispatch => {
    dispatch({ type: SWEETALERT, payload });
  };
};
// change photo swipe
const changePhotoSwipe = payload => {
  return dispatch => {
    dispatch({ type: PHOTOSWIPE, payload });
  };
};
// change modal member
const changeModalMember = payload => {
  return dispatch => {
    dispatch({ type: MODALMEMBER, payload });
  };
};
// change modal spot
const changeModalSpot = payload => {
  return dispatch => {
    dispatch({ type: MODALSPOT, payload });
  };
};
// change modal upload spot photo
const changeModalPhoto = payload => {
  return dispatch => {
    dispatch({ type: MODALPHOTO, payload });
  };
};
// change sweet alert
const changeModalReview = payload => {
  return dispatch => {
    dispatch({ type: MODALREVIEW, payload });
  };
};
// change loading
const changeLoading = payload => {
  return dispatch => {
    // console.log(dispatch);
    dispatch({ type: LOADING, payload });
  };
};
// change cart
const changeCartToggle = payload => {
  return dispatch => {
    dispatch({ type: OPENCART, payload });
  };
};
// change submit status
const changeSubmitting = payload => {
  return dispatch => {
    dispatch({ type: SUBMITTING, payload });
  };
};
// change submit status
const changeScreenWidth = payload => {
  return dispatch => {
    dispatch({ type: SCREENWIDTH, payload });
  };
};

export default {
  changeScreen,
  changeSweetAlert,
  changePhotoSwipe,
  changeLoading,
  changeModalMember,
  changeModalReview,
  changeSubmitting,
  changeScreenWidth,
  changeCartToggle,
  changeModalSpot,
  changeModalPhoto,
};
