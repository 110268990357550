export default ctx => {
  let host = '';
  if (ctx && ctx.req) {
    host = ctx.req.headers.host;
  } else {
    host = window.location.host;
  }
  const host_split = host.split('.');
  let subdomain = host_split[0];
  if (subdomain === 'dv-t5' && host_split[1] !== 'trazy' && host_split[1] !== 'localhost') {
    subdomain = host_split[1];
  }
  return subdomain;
};
