import { SETRESERVED } from '../types';

const setReserved = (payload) => {
  return (dispatch) => {
    dispatch({type: SETRESERVED, payload});
  };
};

export default {
  setReserved,
};
