const Theme = {
  // 반응형
  // xxs: "(max-width: 320px)", // iphone 5
  xs_max: '(max-width: 580px)',
  sm: '(min-width: 581px)',
  sm_max: '(max-width: 768px)',
  md: '(min-width: 769px)', // tablet
  md_max: '(max-width: 990px)',
  lg: '(min-width: 991px)', // desktop
  lg_max: '(max-width: 1140px)',
  xlg: '(min-width: 1141px)',
  // 스타일가이드 색상
  brand: '#f05634',
  brand_hover: '#e44a28',
  brandsub: '#6cc9c9',
  dark: '#0e0e10',
  darker_gray: '#3c3c46',
  dark_gray: '#7c7c83',
  gray: '#b4b4be',
  light_gray: '#ced4da',
  lighter_gray: '#fafafa',
  // 제플린 파레트
  trazy_og: '#f05634',
  light_og: '#f3785b',
  light_og1: '#fff1ed',
  light_og2: '#ffccbc',
  light_og3: '#fcddd6',
  check_og: '#f4856b',
  pastel_og: '#f06544',
  dark_gy: '#464646',
  mid_gy: '#666666',
  mid_gy2: '#333333',
  gy: '#999999',
  light_gy1: '#f9f9f9',
  light_gy2: '#f3f3f3',
  light_gy3: '#cccccc',
  light_gy4: '#aaaaaa',
  dark_mint: '#0097a7',
  trazy_mint: '#6cc9c9',
  light_mint: '#b1e8e6',
  light_mint2: '#d8f3f2',
  light_mint3: '#86d8d9',
  mint_hover: '#53b8b8',
  line_gy: '#eaeaea',
  very_light_gy: '#e3e3e3',
  fb: '#4973e2',
  fb_hover: '#3b64d5',
  light_green: '#a0c47b',
  light_blue: '#82beec',
  light_purple: '#b181db',
  light_red: '#e85f5f',

  error: '#f03e3e',

  // 배경
  body_bg: '#fff',
  // link: "#222",
  link: '#4a90e2',

  // 텍스트 색상
  text: '#222',

  // 글꼴
  light: 300,
  normal: 400,
  medi: 500,
  bold: 700,
  bolder: 900,

  // 그리드
  // grid_gutter_width: '30px',
  container_sm: '670px',

  // 폰트
  sans_serif: `"Roboto", "Segoe UI", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "Helvetica Neue", Helvetica, Arial, "Trebuchet MS", sans-serif;`,
  serif: `Georgia, "Times New Roman", Times, serif;`,
  segoe: `"Segoe UI", "Apple SD Gothic Neo", "맑은 고딕", "Malgun Gothic", "Helvetica Neue", Helvetica, Arial, "Trebuchet MS", sans-serif;`,
  // 스타일
  p_style: `
   font-size: 14px;
   font-weight: 400;
   line-height: 1.42857143;
   letter-spacing: normal;
   color: #464646;`,
};

export default Theme;
