// this file will have the task that we have to perform with cookies/ react-native you need to put your async storage code here.
export const createCookie = (name, value, days, domain) => {
  let _value = value;
  if (name !== 'googtrans') {
      _value = encodeURIComponent(value);
  }
  let expires;

  if (days > 0) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toGMTString();
  } else {
      expires = "";
  }
  let _domain = typeof domain === "undefined" ? "" : "; domain="+domain;
  document.cookie = encodeURIComponent(name) + "=" + _value + expires + "; path=/" + _domain;
}

export const readCookie = (name) => {
  let nameEQ = encodeURIComponent(name) + "=";
  let ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}

export const eraseCookie = (name, domain) => {
  createCookie(name, "", -1, domain);
}
