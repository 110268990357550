import { DIMENSION } from '../types';

const setDimension = str => {
  return dispatch => {
    dispatch({ type: DIMENSION, payload: str });
  };
};

export default {
  setDimension,
};
