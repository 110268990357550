// subdomain
export const SUBDOMAIN = 'subdomain' as const;

// device
export const SERVER = 'server' as const;
export const DEVICE = 'device' as const;

// authentication
export const REGISTER = 'register' as const;
export const AUTHENTICATE = 'authenticate' as const;
export const DEAUTHENTICATE = 'deauthenticate' as const;
export const USER = 'user' as const;

// authentication - business
export const REGISTER_BUSINESS = 'register/business' as const;
export const AUTHENTICATE_BUSINESS = 'authenticate/business' as const;
export const DEAUTHENTICATE_BUSINESS = 'deauthenticate/business' as const;
export const USER_BUSINESS = 'user/business' as const;

// authentication - partner
export const AUTHENTICATE_PARTNER = 'authenticate/partner' as const;
export const DEAUTHENTICATE_PARTNER = 'deauthenticate/partner' as const;
export const USER_PARTNER = 'user/partner' as const;

// authentication - partner
export const AUTHENTICATE_COMPANY = 'authenticate/company' as const;
export const DEAUTHENTICATE_COMPANY = 'deauthenticate/company' as const;
export const USER_COMPANY = 'user/company' as const;

// ui
export const SWEETALERT = 'sweetalert' as const;
export const MODALMEMBER = 'modalmember' as const;
export const MODALSPOT = 'modalspot' as const;
export const MODALPHOTO = 'modalphoto' as const;
export const MODALREVIEW = 'modalreview' as const;
export const LOADING = 'loading' as const;
export const SCREENWIDTH = 'screenwidth' as const;
export const SUBMITTING = 'submitting' as const;
export const PHOTOSWIPE = 'photoswipe' as const;
export const OPENCART = 'opencart' as const;

// currency
export const CURRENCYINFO = 'currencyinfo' as const;
export const CURRENCY = 'currency' as const;

// translation
export const TRANSLATION = 'translation' as const;

// you recently checked out
export const RECENTLY = 'recently' as const;

// cart
export const MERGECART = 'mergecart' as const;
export const REMOVECART = 'removecart' as const;
export const SETCART = 'setcart' as const;

// reservation
export const SETRESERVATION = 'setreservation' as const;
export const SETRESERVED = 'setreserved' as const;

// point
export const SETSUMPOINT = 'setsumpoint' as const;
export const SETEXPIREDPOINT = 'setexpiredpoint' as const;

// wishlist
export const ADDWISH = 'addwish' as const;
export const REMOVEWISH = 'removewish' as const;

// cities
export const CITIES = 'cities' as const;

// blog
export const BLOG = 'blog' as const;

// dimension
export const DIMENSION = 'dimension' as const;

// language
export const LANGUAGEINFO = 'languageinfo' as const;

